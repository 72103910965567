import React, {useState} from "react";
import HeaderBreadCrumb from "../../BreadCrumb/Breadcrumb";
import Aux from "../../../hoc/Auxiliary"
import {DataTable} from 'primereact/datatable';
import {Column} from "primereact/column";
import classes from "./DataCollectionList.module.css"
import {isAuthorized} from "../../../api/authorization/authorization-service";
import {SelectButton} from 'primereact/selectbutton';
import TemplatePaginator from "../../commun/TemplatePaginator/TemplatePaginator";
import DCListHeader from "./Header/DCListHeader";
import CreateDataCollectionButton from "./CreateDcButton/CreateDataCollectionButton";
import DataCollectionErrorList from "./Errors/DataCollectionErrorList";
import DCStatusFilter from "../../commun/DCStatusFilter/DCStatusFilter";
import StatusBodyTemplate from "../../commun/Table/StatusColumn/StatusBodyTemplate";
import {statusFilterProperties} from "../../../api/utils/filterProperties";
import ActionColumnPublishedDc from "./ActionBody/ActionColumnPublishedDc";
import {Chip} from "primereact/chip";
import ModelVersionColumn from "./ModelVersionColumn/ModelVersionColumn";
import DraftDataCollections from "./DraftDataCollectionsList/DraftDataCollections";
import {ProgressBar} from "primereact/progressbar";
import ManualCheckColumn from "./ManualCheckColumn/ManualCheckColumn";
import {sortById} from "../../../api/utils/sortArrays";
import {classNames} from "primereact/utils";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getDCDetailsById} from "../../../api/dataCollection/dataCollection-service";
import {Skeleton} from 'primereact/skeleton';
import DataCollectionsInProgress from "./DataCollectionInProgressList/DataCollectionsInProgress";

const DataCollectionsList = ({
                                 dcListOthers,
                                 statusList,
                                 dcListDraft,
                                 reload,
                                 setReload,
                                 dcDetails,
                                 dcListInProgress

                             }) => {
    const [loadingExpandedRow, setLoadingExpandedRow] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [value, setValue] = useState('PUBLISHED');
    const options = ['PUBLISHED', 'DRAFT', 'CREATION IN PROGRESS'];
    const [dcList, setDcList] = useState([...dcListOthers]);
    const [draftList, setDraftList] = useState([...dcListDraft])
    const [expandedRows, setExpandedRows] = useState([]);
    const [progressMsg, setProgressMsg] = useState('Creating');
    const [idDeleting, setIdDeleting] = useState([]);
    const isSelectable = (id) => !idDeleting.includes(id);
    const rowClassName = (data) => {
        return isSelectable(data.id) ? '' : 'p-disabled'
    };
    const statusFilterTemplate = () => {
        return <DCStatusFilter dataCollectionsList={dcListOthers} filterDataCollections={setDcList}
                               statusList={statusList}/>
    }

    /**
     * Update DataCollection List by replacing one element
     * and sort all by ID to keep their position in the screen
     * @param newDc DataCollection that will be changed
     * */
    const updateDCList = (newDc) => {
        setDcList(sortById([...dcList.filter(e => e.id !== newDc.id), newDc], "id"))
    }
    const getProgressBackground = (val) => {
        if (val === 1) {
            return "green"
        } else return "orange"
    }
    const publishProgress = (row) => (<ProgressBar value={(row['averagePublish'] * 100).toFixed(0)}
                                                   color={getProgressBackground(row['averagePublish'])}/>)


    const header = (
        <DCListHeader>
            <div className="right-0 md-0 text-red font-bold flex align-items-center">
                <CreateDataCollectionButton/>
            </div>
        </DCListHeader>
    );

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }
    const actionsBodyTemplateForPublish = (rowData) => ((idDeleting.includes(rowData.id)) ?
        <div className="flex justify-content-around">
            <span className="vertical-align-middle"> {progressMsg} </span>
            <i className="pi pi-spin pi-spinner" style={{fontSize: '1.2rem', padding: '0.15rem'}}></i></div> :
        <ActionColumnPublishedDc rowData={rowData}
                                 dcList={dcList} setDcList={setDcList} setIdDeleting={setIdDeleting}
                                 setProgressMsg={setProgressMsg} idDeleting={idDeleting}
                                 isDcCreationInProgress={rowData.dataCollectionStatus.code === "DC_CREATION_IN_PROGRESS"}/>)
    const modelVersionColumnTemplate = (rowData) => (<ModelVersionColumn rowData={rowData}/>)

    const manualCheckResult = (rowData) => (
        <ManualCheckColumn rowData={rowData} reload={reload} setReload={setReload}
                           updateDataCollections={updateDCList}/>)


    const nameColumnStructure = (r) => {
        if (r['errorNotificationPresent'] && isAuthorized("AR032")) {
            return <DataCollectionErrorList row={r}/>
        } else {
            return r.name;
        }
    }
    const findDetailsById = (id) => {
        return expandedRows.filter(e => e.id === id)[0];
    }
    const rowExpansionTemplate = (data) => {
        let details = findDetailsById(data.id)

        let groupedPermissions = groupPermissionsByParticipant(details.participants);
        return loadingExpandedRow ? <Skeleton width="90vw" height="4rem"></Skeleton> : (
            <div className="p-3" style={{width: '90vw'}}>
                {details.description !== "" &&
                    <div className={classes.description}><h5>Description :</h5>{details.description}</div>}
                {details.copiedFrom !== null && <h5>Copied From : {data.copiedFrom.copiedFromDc}</h5>}
                <h5>Categories :</h5>
                {<DataTable scrollable value={details.categories}>
                    <Column style={{width: '40%'}} field="name" header="Name" sortable></Column>
                    <Column header="Sheets" body={sheetsBodyTemplate}></Column>
                </DataTable>}
                <h5>Permissions :</h5>
                {<DataTable scrollable value={groupedPermissions}>
                    <Column style={{width: '20%'}} field="participant" header="User group" sortable></Column>
                    <Column header="Permissions" body={permissionsBodyTemplate}></Column>
                </DataTable>}
            </div>
        );
    };
    const sheetsBodyTemplate = (cat) => {
        return <Aux>
            {cat.sheets.map((s) => {
                let index = 1 + (Math.random() * 1000);
                return <Chip key={index} label={(
                    s.targetYearStart !== 0 ?
                        <div>{s.name}<b>({s.targetYearStart}-{s.targetYearEnd})</b></div> :
                        <div>{s.name}</div>
                )}
                             className={"mb-2"}/>
            })}
        </Aux>
    };
    const MarketNodeBodyTemplate = (p) => {
        return <Aux>{p.marketNodes.map((m) => {
            let index = 1 + (Math.random() * 1000);
            return <Chip key={index} label={m.name}
                         className={"mb-2"}/>
        })}</Aux>
    }
    const permissionsBodyTemplate = (p) => {
        return <DataTable style={{width: '100%'}} value={p.permissions}>
            <Column style={{width: '25%'}} field="permissionName" header="Permission" sortable></Column>
            <Column header="Market Nodes" body={MarketNodeBodyTemplate}></Column>
        </DataTable>

    }

    const groupPermissionsByParticipant = (participants) => {
        //group by participant name
        let data = []
        let groupedByParticipant = participants.reduce(function (r, a) {
            r[a.participant.name] = r[a.participant.name] || [];
            r[a.participant.name].push(a);
            return r;
        }, Object.create(null));
        //group by permission

        for (let key in groupedByParticipant) {
            let ar = []
            let p = groupedByParticipant[key].reduce(function (r, a) {
                r[a.permissionName.name] = r[a.permissionName.name] || [];
                r[a.permissionName.name].push(a.marketNode);
                return r;
            }, Object.create(null));
            for (let key in p) {
                ar.push({permissionName: key, marketNodes: p[key]})
            }
            groupedByParticipant[key] = ar
            data.push({"participant": key, "permissions": ar})
        }
        return data
    }

    const getDataCollectionDetails = async (e) => {
        let currentId = 0;
        for (let outerkey in e.data) {
            if (e.data[outerkey] === true) {
                currentId = outerkey
                break;
            }
        }
        let size = expandedRows.length
        let currentRows = expandedRows.filter(x => x.id != currentId)
        e.data = currentRows
        if (currentId !== 0 && size == currentRows.length) {
            await getDCDetailsById(currentId).then(res => {
                e.data = [...e.data, res]
            })
        }
        setExpandedRows(e.data)
    }

    const othersDataCollections = () => {
        return (<div>
            <div className=" card">
                <DataTable value={dcList}
                           expandedRows={expandedRows} onRowToggle={(e) => {
                    setLoadingExpandedRow(true)
                    getDataCollectionDetails(e)
                    setLoadingExpandedRow(false)
                }}
                           rowExpansionTemplate={rowExpansionTemplate}
                           expandedRowIcon="pi pi-chevron-up"
                           collapsedRowIcon="pi pi-chevron-down"
                           dataKey="id"
                           header={header}
                           size="small"
                           resizableColumns
                           stripedRows
                           showGridlines
                           filters={statusFilterProperties}
                           paginator paginatorTemplate={TemplatePaginator}
                           first={first} rows={rows} onPage={onCustomPage}
                           paginatorClassName="justify-content-end" className="mt-6"
                           rowClassName={rowClassName}
                >
                    <Column expander={true} style={{minWidth: '2rem', maxWidth: '2rem'}}/>
                    <Column
                        field="name"
                        style={{minWidth: "8rem", maxWidth: "10rem", whiteSpace: 'normal', wordWrap: 'break-word'}}
                        body={(r) => nameColumnStructure(r)}
                        sortable
                        header="Name"
                        filter/>
                    <Column field="dataStatus.name" sortable header="Status"
                            filterMenuStyle={{width: '14rem', height: '6rem'}}
                            style={{minWidth: '10rem', width: '10rem'}}
                            showFilterMatchModes={false}
                            body={StatusBodyTemplate}
                            filter
                            showApplyButton={false}
                            showClearButton={false}
                            filterElement={statusFilterTemplate}/>
                    <Column header="Publish Progress" body={publishProgress} style={{width: "10rem"}}/>
                    <Column field="startFrom" header="Open from" style={{width: "6rem"}}/>
                    <Column field="deadline" header="Deadline" style={{width: "6rem"}}/>
                    <Column field="participatingCountries"
                            header="Total participants" style={{width: "8rem"}}/>
                    <Column header="PEMMDB Version" body={modelVersionColumnTemplate}
                            style={{
                                minWidth: "8rem",
                                maxWidth: "10rem",
                                whiteSpace: 'normal',
                                wordWrap: 'break-word'
                            }}/>
                    <Column body={manualCheckResult} header="Manual check" style={{width: "5rem"}}/>
                    <Column body={actionsBodyTemplateForPublish} header="Actions "
                            style={{minWidth: "12rem", width: '12rem'}}/>
                </DataTable>
                <ConfirmDialog/>
            </div>
        </div>)

    }


    const draftDataCollections = () => {
        return (<DraftDataCollections dcListDraft={dcListDraft} setDraftList={setDraftList} draftList={draftList}
                                      header={header} first={first} rows={rows} onCustomPage={onCustomPage}/>)

    }

    const dataCollectionsInProgress = () => {
        return (<DataCollectionsInProgress dcListInProgress={dcListInProgress}
                                           rows={rows} rowExpansionTemplate={rowExpansionTemplate} header={header}
                                           first={first} onCustomPage={onCustomPage}/>)

    }
    const selectStatus = (val) => {
        if (val != null) {
            setValue(val);
        }
    }
    const showSelectButton = () => {
        if (isAuthorized("AR08")) {
            return (<SelectButton
                    value={value}
                    options={options}
                    className={classNames(classes.selectBtn, "ml-0")}
                    onChange={(e) => selectStatus(e.value)}/>
            )
        } else {
            return null;
        }
    }
    const showDataCollections = () => {
        if (isAuthorized("AR08")) {
            return (getDataCollections());
        } else {
            return (othersDataCollections());
        }
    }
    const getDataCollections = () => {
        if (value === 'DRAFT')
            return draftDataCollections()
        else if (value === "PUBLISHED")
            return othersDataCollections()
        else {
            return dataCollectionsInProgress()
        }
    }
    return (
        <Aux>
            <HeaderBreadCrumb items={["Data Collections"]}/>
            {showSelectButton()}
            {showDataCollections()}
        </Aux>
    )
}
export default DataCollectionsList;
