import React, {useEffect, useState} from "react";
import Auxiliary from "../../../hoc/Auxiliary";
import {getAllDataCollectionDetailsByExcludedStatus} from "../../../api/dataCollection/dataCollection-service";
import {updateDataCollectionDetails} from "../../../api/dataCollection/dataCollection-utils";
import DataCollectionList from "../../../components/StudyTeams/DataCollectionList/DataCollectionList";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import HeaderBreadCrumb from "../../../components/BreadCrumb/Breadcrumb";
import {useNavigate} from "react-router-dom";
import {onServerCallError} from "../../../api/utils/errors";
import {ERROR_PATH} from "../../../api/static-data/Configurations";

const StudyTeamsDataCollections = () => {
    const [publishedDC, setPublishedDC] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const fetchDC = async () => {
        await getAllDataCollectionDetailsByExcludedStatus(["DRAFT", "DC_CREATION_IN_PROGRESS"], true)
            .then(res => {
                // const persisted = getPersistedDataCollections(res)
                setPublishedDC(updateDataCollectionDetails(res))
            })
            .catch(_ => {
                onServerCallError("Failed to retrieve dataCollections details", "Could not retrieve dataCollections details",
                    () => {
                        navigate(ERROR_PATH);
                    })
            })
    }
    useEffect(() => {
        try {
            fetchDC().then(() => {
                setIsLoading(false)
            })
        } catch (e) {
            onServerCallError("Error loading page", "An error occurs while displaying dataCollection details ",
                () => {
                    navigate(ERROR_PATH);
                })
        }
    }, [])

    const content = isLoading
        ? (<LoadingScreen/>)
        : (<DataCollectionList nonDraftDataCollections={publishedDC}/>)

    return (
        <Auxiliary>
            <HeaderBreadCrumb items={["Study team space"]}/>

            <br/>
            <br/>
            {content}
        </Auxiliary>
    )
}

export default StudyTeamsDataCollections;
