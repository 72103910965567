import React from "react";
import Aux from "../../../../hoc/Auxiliary";
import {Button} from "primereact/button";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import DataCollectionQueriesButton from "../QueriesButton/DataCollectionQueriesButton";
import DataCollectionDetailsButton from "../DetailsButton/DetailsButton";
import {canExportData} from "../../../../api/authorization/rules";
import ExportButton from "../../../DataCollectionDetails/BodyTemplate/ExportButton/ExportButton";
import {useNavigate} from "react-router-dom";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";
import EditDataCollectionButton from "../EditButton/EditDataCollectionButton";
import {convert_dd_mm_yyyy_to_Date} from "../../../../api/utils/DateFormatter";
import {updateProperty} from "../../../../api/utils/conditions-utils";
import {removeFromSessionStorage} from "../../../../api/State/manage-state-storage-service";
import {confirmDialog} from "primereact/confirmdialog";

import {deletePublishedDataCollection} from "../../../../api/dataCollection/dataCollection-service";
import {showSuccess} from "../../../../api/notification/ToastManager";
import classes from "../DataCollectionList.module.css";
import classesP from "../../DataCollectionCreator/ParticipantsSetting/ParticipantsSetting.module.css";

const ActionColumnPublishedDc = ({rowData, dcList, setDcList, setIdDeleting, idDeleting,setProgressMsg,isDcCreationInProgress}) => {
    const navigate = useNavigate();

    const getDashboards = (dcName, dcID, _) => {
        removeFromSessionStorage("dataCollection");
        navigate("/data_collections/" + normalizePathItem(dcName) + "/visualisation/dashboards",
            {
                state: {
                    dataCollection: {
                        name: dcName,
                        id: dcID
                    }
                }
            })
    }
    const confirmDeleteDc = (dcId, dcName) => {
        confirmDialog({
            message: 'Are you sure you want to delete published data collection  "' + dcName + '" ?',
            header: 'Confirmation',
            accept: () => {
                setIdDeleting([dcId, ...idDeleting])
                setProgressMsg("Deleting")
                deletePublishedDataCollection({"dcId": dcId, "dcName": dcName}).then(r => {
                    if (r.status === 200) {
                        setDcList(dcList.filter(x => x.id !== dcId))
                        showSuccess("Data collection deleted successfully !")

                    }
                })
            },
            reject: () => {
            },
            icon: 'pi pi-exclamation-triangle',
            appendTo: "self",
            acceptClassName: `${classes.buttons} ${classesP.actionBtn}`,
            rejectClassName: `${classes.buttons} ${classesP.actionBtn}`
        });

    }
    return (
        <Aux>

                {isDcCreationInProgress? <div className=" grid p-0 ">
                    <div className="col p-0 m-0 flex flex ">
                        <span className="vertical-align-middle"
                              style={{fontSize: "14px", paddingLeft: "0.5rem", paddingRight: "0.5rem"}}> Creation in progress  </span>
                        <i className="pi pi-spin pi-spinner" style={{fontSize: '1rem', padding: '0.15rem'}}></i></div>
                    <div className="col p-0 m-0 flex flex ">
                        <DataCollectionDetailsButton rowData={rowData}/>
                    </div>
                    </div>
                    :
                    <div className=" grid p-0 ">
                        <div className="col m-0 flex align-items-center justify-content-center p-0 p-button-rounded-sm">
                            {updateProperty(canExportData(),
                                (<ExportButton
                                    dcName={rowData?.name}
                                    dcId={rowData?.id}
                                    isDisplayed={true}
                                    disabled={convert_dd_mm_yyyy_to_Date(rowData.startFrom) >= new Date()}
                                    messageOnDisable={"Will be enabled when dataCollection starts."}

                                />)
                                , null)}
                        </div>
                        <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                            <Button icon="pi pi-chart-bar"
                                    tooltip="Data visualisation"
                                    tooltipOptions={{position: 'top', showOnDisabled: true}}
                                    onClick={(e) => getDashboards(rowData.name, rowData.id, e)}
                                    className=" p-button-rounded-sm p-button-text"
                                    style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                            />
                        </div>
                        <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                            {
                                updateProperty(isAuthorized("AR030"), <DataCollectionQueriesButton
                                    rowData={rowData}/>, null)
                            }
                        </div>
                        <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                            {
                                updateProperty(isAuthorized("AR08"), <EditDataCollectionButton
                                    rowData={rowData}/>, null)
                            }
                        </div>
                        <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                            {
                                updateProperty(isAuthorized("AR08"), <Button icon=" pi pi-trash"
                                                                             tooltip=" Delete data collection"
                                                                             tooltipOptions={{
                                                                                 position: 'top',
                                                                                 showOnDisabled: true
                                                                             }}
                                                                             className=" p-button-rounded-sm p-button-text"
                                                                             onClick={() => confirmDeleteDc(rowData.id, rowData.name)}
                                                                             style={{
                                                                                 height: '0.5rem',
                                                                                 width: '1.5rem',
                                                                                 color: '#487c84'
                                                                             }}/>, null)
                            }
                        </div>
                        <div>
                            <DataCollectionDetailsButton rowData={rowData}/>
                        </div>
                    </div>
                    }


                </Aux>
                    )


                }
            export default ActionColumnPublishedDc;
