import {useNavigate} from "react-router-dom";
import {removeFromSessionStorage} from "../../../../api/State/manage-state-storage-service";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";
import Aux from "../../../../hoc/Auxiliary";
import DataCollectionDetailsButton from "../DetailsButton/DetailsButton";
import {updateProperty} from "../../../../api/utils/conditions-utils";
import {canExportData} from "../../../../api/authorization/rules";
import ExportButton from "../../../DataCollectionDetails/BodyTemplate/ExportButton/ExportButton";
import {convert_dd_mm_yyyy_to_Date} from "../../../../api/utils/DateFormatter";
import {Button} from "primereact/button";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import DataCollectionQueriesButton from "../QueriesButton/DataCollectionQueriesButton";
import React from "react";

const ActionColumnDcInProgress = ({rowData}) => {
    const navigate = useNavigate();

    const getDashboards = (dcName, dcID, _) => {
        removeFromSessionStorage("dataCollection");
        navigate("/data_collections/" + normalizePathItem(dcName) + "/visualisation/dashboards",
            {
                state: {
                    dataCollection: {
                        name: dcName,
                        id: dcID
                    }
                }
            })
    }

    return (
        <Aux>
            <div className=" grid p-0 ">
                <div className="col m-0 flex align-items-center justify-content-center p-0 p-button-rounded-sm">
                    {updateProperty(canExportData(),
                        (<ExportButton
                            dcName={rowData?.name}
                            dcId={rowData?.id}
                            isDisplayed={true}
                            disabled={convert_dd_mm_yyyy_to_Date(rowData.startFrom) >= new Date()}
                            messageOnDisable={"Will be enabled when dataCollection starts."}

                        />)
                        , null)}
                </div>
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    <Button icon="pi pi-chart-bar"
                            tooltip="Data visualisation"
                            tooltipOptions={{position: 'top', showOnDisabled: true}}
                            onClick={(e) => getDashboards(rowData.name, rowData.id, e)}
                            className=" p-button-rounded-sm p-button-text"
                            style={{height: '0.5rem', width: '1.5rem', color: '#487c84'}}
                    />
                </div>
                <div className=" col p-0 m-0 flex align-items-center justify-content-center">
                    {
                        updateProperty(isAuthorized("AR030"), <DataCollectionQueriesButton
                            rowData={rowData}/>, null)
                    }
                </div>
                <div>
                    <DataCollectionDetailsButton rowData={rowData}/>
                </div>
            </div>


        </Aux>
    )


}
export default ActionColumnDcInProgress;
