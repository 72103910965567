import React, {useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import TemplatePaginator from "../../../commun/TemplatePaginator/TemplatePaginator";
import StatusBodyTemplate from "../../../commun/Table/StatusColumn/StatusBodyTemplate";
import {ProgressBar} from "primereact/progressbar";
import ModelVersionColumn from "../ModelVersionColumn/ModelVersionColumn";
import {statusFilterProperties} from "../../../../api/utils/filterProperties";
import {ConfirmDialog} from "primereact/confirmdialog";
import {isAuthorized} from "../../../../api/authorization/authorization-service";
import DataCollectionErrorList from "../Errors/DataCollectionErrorList";
import ActionColumnDcInProgress from "../ActionBody/ActionColumnDcInProgress";


const DataCollectionsInProgress = ({dcListInProgress, rows, first, header, onCustomPage}) => {
    const publishProgress = (row) => (<ProgressBar value={(row['averagePublish'] * 100).toFixed(0)}
                                                   color={getProgressBackground(row['averagePublish'])}/>)
    const getProgressBackground = (val) => {
        if (val === 1) {
            return "green"
        } else return "orange"
    }

    const nameColumnStructure = (r) => {
        if (r['errorNotificationPresent'] && isAuthorized("AR032")) {
            return <DataCollectionErrorList row={r}/>
        } else {
            return r.name;
        }
    }
    const actionsBodyTemplateForDcInProgress = (rowData) => (

        <ActionColumnDcInProgress rowData={rowData}
        />)

    const modelVersionColumnTemplate = (rowData) => (<ModelVersionColumn rowData={rowData}/>)
    return (<div>
        <div className=" card">
            <DataTable value={dcListInProgress}
                       dataKey="id"
                       header={header}
                       size="small"
                       resizableColumns
                       stripedRows
                       showGridlines
                       filters={statusFilterProperties}
                       paginator paginatorTemplate={TemplatePaginator}
                       first={first} rows={rows} onPage={onCustomPage}
                       paginatorClassName="justify-content-end" className="mt-6">
                <Column
                    field="name"
                    style={{minWidth: "8rem", maxWidth: "10rem", whiteSpace: 'normal', wordWrap: 'break-word'}}
                    body={(r) => nameColumnStructure(r)}
                    sortable
                    header="Name"
                    filter/>
                <Column field="dataStatus.name" sortable header="Status"
                        filterMenuStyle={{width: '14rem', height: '6rem'}}
                        style={{minWidth: '10rem', width: '10rem'}}
                        showFilterMatchModes={false}
                        body={StatusBodyTemplate}
                        filter
                        showApplyButton={false}
                        showClearButton={false}/>
                <Column header="Publish Progress" body={publishProgress} style={{width: "10rem"}}/>
                <Column field="startFrom" header="Open from" style={{width: "6rem"}}/>
                <Column field="deadline" header="Deadline" style={{width: "6rem"}}/>
                <Column field="participatingCountries"
                        header="Total participants" style={{width: "8rem"}}/>
                <Column header="PEMMDB Version" body={modelVersionColumnTemplate}
                        style={{
                            minWidth: "8rem",
                            maxWidth: "10rem",
                            whiteSpace: 'normal',
                            wordWrap: 'break-word'
                        }}/>
                <Column body={actionsBodyTemplateForDcInProgress} header="Actions "
                        style={{minWidth: "12rem", width: '12rem'}}/>
            </DataTable>
            <ConfirmDialog/>
        </div>
    </div>)

}
export default DataCollectionsInProgress;
