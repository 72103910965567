import React, {useEffect, useState} from "react";
import Aux from "../../hoc/Auxiliary";
import DataCollectionsList from "../../components/DataCollection/DataCollections/DataCollectionsList";
import {
    findDataCollectionByParticipant,
    getAllDataCollectionDetailsByExcludedStatus,
    getDataCollectionsGeneralInfoByStatus
} from "../../api/dataCollection/dataCollection-service";
import {isAuthorized} from "../../api/authorization/authorization-service";
import {updateDataCollectionDetails} from "../../api/dataCollection/dataCollection-utils";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {getUniqueObjectsByProperty} from "../../api/utils/UniqueObjectsByProperty";
import {useNavigate} from "react-router-dom";
import {onServerCallError} from "../../api/utils/errors";
import {ERROR_PATH} from "../../api/static-data/Configurations";


const DataCollectionBuilder = () => {

    const [dataCollectionsDraft, setDataCollectionsDraft] = useState([]);
    const [dataCollectionsCreationInProgress, setDataCollectionsCreationInProgress] = useState([]);
    const [dataCollectionsOthers, setDataCollectionsOthers] = useState([]);
    const [dataCollectionsOthersDetails, setDataCollectionsOthersDetails] = useState([]);
    const [status, setStatus] = useState([]);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const fetchData = async () => {
        if (isAuthorized("AR019")) {
            // get all data collections
            await getDataCollectionsGeneralInfoByStatus("DRAFT")
                .then(res => {
                    setDataCollectionsDraft(updateDataCollectionDetails(res))
                }).catch(_ => {
                    onServerCallError("Failed to retrieve draft dataCollections",
                        "An error occurs while retrieving draft dataCollections",
                        () => {
                        })
                })
            await getDataCollectionsGeneralInfoByStatus("DC_CREATION_IN_PROGRESS")
                .then(res => {
                    setDataCollectionsCreationInProgress(updateDataCollectionDetails(res))
                }).catch(_ => {
                    onServerCallError("Failed to retrieve draft dataCollections",
                        "An error occurs while retrieving draft dataCollections",
                        () => {
                        })
                })
            await getAllDataCollectionDetailsByExcludedStatus(["DRAFT","DC_CREATION_IN_PROGRESS"], true)
                .then(res => {
                    setDataCollectionsOthers(updateDataCollectionDetails(res))
                }).catch(_ => {
                    onResponseError()
                })
        } else {
            // List Data Collections of Allocated Region
            await findDataCollectionByParticipant(true)
                .then(res => {
                    setDataCollectionsOthers(updateDataCollectionDetails(res))
                }).catch(_ => {
                    onResponseError()
                })
            await findDataCollectionByParticipant(false)
                .then(res => {
                    setDataCollectionsOthersDetails(res)
                }).catch(_ => {
                    onResponseError()
                })
        }

    }

    useEffect(() => {
        let st = dataCollectionsOthers.map((dc) => dc.dataStatus);
        setStatus(getUniqueObjectsByProperty(st, "name"));
    }, [dataCollectionsOthers])

    useEffect(() => {
        try {
            fetchData().then(() => {
                setIsLoading(false);
            });
        } catch (e) {
            onResponseError()
        }
        }, [reload]
    );

    const onResponseError = () => {
        onServerCallError("Error loading page", "An error occurs while retrieving dataCollections details", () => {
            navigate(ERROR_PATH);
        })
    }

    let content = isLoading
        ? (<LoadingScreen/>)
        : (<Aux>
            <DataCollectionsList
                dcListOthers={dataCollectionsOthers}
                dcDetails={dataCollectionsOthersDetails}
                statusList={status}
                dcListDraft={dataCollectionsDraft}
                dcListInProgress={dataCollectionsCreationInProgress}
                reload={reload}
                setReload={setReload}/>
        </Aux>)
    return <div>{content}</div>

}
export default DataCollectionBuilder;
