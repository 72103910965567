import {isClosed} from "./dataCollection-status";
import {formatDate} from "../utils/DateFormatter";
import {getParticipants} from "./particpants-service";
import {getPermissions} from "./permissions-service";
import {getAllDataModelsWithStatusPublished} from "../DataModel/data-model";
import {
    getAllDataCollectionDetailsByExcludedStatus,
    getDataCollectionsGeneralInfoByStatus
} from "./dataCollection-service";
import {sortById} from "../utils/sortArrays";

export const emptyDataCollection = {
    name: "",
    model: "",
    openFrom: "",
    deadline: "",
    description: "",
    categories: [{name: "", sheets: []}],
    modifiedIn: null,
    participants: [],
    createdBy: '',
    mailMessage: "",
    modifiedBy: null,
    createdIn: new Date(),
    status: {
        code: "NO_DATA_AVAILABLE",
        name: "No data available",
        context: "CATEGORY_STEPS",
        color: "grey"
    }
};

export const dataCollectionDataStepBeforePersistStatus = [
    'NO_DATA_AVAILABLE',
    'PUBLISHED',
    'DATA_SUBMISSION',
]


export const updateDataCollectionDetails = (data) => {
    data.map(e => updateDataCollectionDates(e))
    return sortById(data, 'id');
}
export const checkGeneralSettingsField = (field) => {
    return field === undefined || field === false;
}

export const updateDataCollectionDates = (dc) => {
    if (isClosed(dc.dataCollectionStatus)) {
        dc.dataStatus = dc.dataCollectionStatus
    }
    if (dc.startFrom !== null) {
        dc.startFrom = formatDate(new Date(Date.parse(dc.startFrom)));
    }
    if (dc.deadline != null) {
        dc.deadline = formatDate(new Date(Date.parse(dc.deadline)));
    }
    return dc
}
export const publishButtonIsDisable = (generalSettings, participants, categories) => {
    return participants.length === 0
        || checkGeneralSettingsField(generalSettings.isDataCollectionNameValid)
        || checkGeneralSettingsField(generalSettings.isDeadlineValid)
        || checkGeneralSettingsField(generalSettings.isOpenFromValid)
        || checkGeneralSettingsField(generalSettings.isPEMMDBVersionValid)
        || categories.length === 0;
}

export const draftButtonIsDisable = (dataCollectionName, generalSettings) => {
    return dataCollectionName.length === 0
        || checkGeneralSettingsField(generalSettings.isDataCollectionNameValid)
        || checkGeneralSettingsField(generalSettings.isPEMMDBVersionValid)

}

export const selectedParticipantsHavePermissions = (participants, permissions) => {
    let sp = participants
        .map((v) => v.groupId)
        .map(groupId => {
            return permissions[groupId] !== undefined && selectedParticipantHavePermissions(permissions[groupId])
        })
    return sp.length === Object.keys(permissions).length
        && [...new Set(sp)].length === 1
        && sp.includes(true);
}
export const selectedParticipantHavePermissions = (permissions) => {
    return Object.keys(permissions).map(p => permissions[p].length > 0).includes(true);
}

export const getSelectedParticipants = (participants) => {
    const participantList = []
    if (participants.length !== undefined) {
        participants.map(x => {
            if (!participantList.find((y) => y.groupId === x.participant.groupId))
                participantList.push(x.participant)
        })
    }
    return participantList;
}

export const getPermissionByParticipants = (participants) => {

    return participants.reduce((acc, item) => {
        if (!acc[item.participant.groupId]) {
            acc[item.participant.groupId] = [];
        }
        if (!acc[item.participant.groupId][item.permissionName.name]) {
            acc[item.participant.groupId][item.permissionName.name] = [];
        }
        acc[item.participant.groupId][item.permissionName.name].push(item.marketNode);
        return acc;
    }, {});
}


export const deleteDataCollectionFromLocalStorage = (data) => {
    delete data.dataCollection['id'];
    delete data.dataCollection['dataStatus'];
    data.dataCollection.categories.forEach(x => {
        delete x['id'];
    })
    if(data.dataCollection.copiedFrom!=null)
    {
        delete  data.dataCollection.copiedFrom['id'];
    }

    data.dataCollection.participants.forEach(x => {
        delete x['id'];
    })
}

export const fetchDataCollections = async () => {
    const data = {
        pemmdbVersions: [],
        draftDataCollections: [],
        nonDraftDataCollections: [],
        participants: [],
        permissionsData: []
    }
    await getAllDataModelsWithStatusPublished().then((res) => {
        data.pemmdbVersions = [...res];
    })
    await getAllDataCollectionDetailsByExcludedStatus(["DRAFT","DC_CREATION_IN_PROGRESS"], true)
        .then(res => {
            data.nonDraftDataCollections = [...res.map(e => {
                return {name: e.name, id: e.id}
            })]

        })
    await getDataCollectionsGeneralInfoByStatus("DRAFT")
        .then(res => {
            data.draftDataCollections = [...res.map(e => {
                return {name: e.name, id: e.id}
            })]
        })
    await getParticipants()
        .then(res => {
            if (res.status === 200) {
                data.participants = [...res.content];
            }
        })
    await getPermissions()
        .then(res => {
            if (res.status === 200) {
                data.permissionsData = [...res.content]
            }
        })
    return data;

}
export const updateParticipantsPermission = (participants, permissions) => {
    let ps = {};
    participants.map(e => e.groupId)
        .forEach(g => {
            if (permissions[g] !== undefined) {
                ps = {...ps, [g]: permissions[g]};
            }
        })
    return ps;
}

export const getPersistedDataCollections = (dataCollectionList) => {
    return [...dataCollectionList.filter(e => !dataCollectionDataStepBeforePersistStatus.includes(e['dataStatus'].code))]
}

export const getPermissionFromOtherDC = (participants, permissionsData) => {
    const permissionsByParticipants = {};
    if (permissionsData.length !== 0) {
        participants.forEach(x => {
            if (!permissionsByParticipants[x.participant.groupId]) {
                const p = {};
                permissionsData.forEach(x => {
                    p[x.name] = [];
                })
                p[""] = [];
                permissionsByParticipants[x.participant.groupId] = p;
            }
        })
        participants.forEach(x => {
            const y = permissionsByParticipants[x.participant.groupId];
            if (y[x.permissionName.name]) {
                y[x.permissionName.name].push(x.marketNode)
            }
        })
    }
    return permissionsByParticipants;
}
